<template>
  <v-card color="blue-grey lighten-5" class="rounded-0 pa-md-5 pa-sm-2 mb-2">
    <v-simple-table dense class="table-bordered">
      <thead class="blue-grey lighten-5">
        <tr class="text-caption grey--text ">
          <th class="trainings__icon">
            #
          </th>
          <th class="text-left font-weight-regular">
            {{ assort == 'day' ? 'День' : 'Сессия' }}
          </th>
          <th class="text-left font-weight-regular">
            Дата
          </th>
          <th class="text-left font-weight-regular">
            Пользователь
          </th>
          <th class="text-center font-weight-regular">
            Уровень
          </th>
          <th class="text-center font-weight-regular">
            Память, %
          </th>
          <th class="text-center font-weight-regular">
            Внимание, %
          </th>
          <th class="text-center font-weight-regular">
            Скорость реакции, %
          </th>
          <th class="text-center font-weight-regular">
            Баллы
          </th>
          <th class="text-center font-weight-regular">
            Время прохождения
          </th>
          <th class="text-center font-weight-regular"></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(gr, ind) in groups">
          <tr :key="ind"> 
            <td class="w-fit px-1">                
              <v-btn x-small tile depressed @click="toggleOpened(ind)" class="trainings__result-count blue-grey lighten-4 px-1  ">{{ gr.length }}</v-btn>
            </td>
            <td class="w-fit px-1">                
              {{ ind + 1 }}
            </td>
            <td>{{ new Date(getTime(gr)) | moment("DD.MM.YYYY HH:mm") }}</td>
            <td>{{ getUserName(gr[0].user_id) }}</td>
            <td class="text-center">{{ getCurrentLavel(gr) }}</td>
            <td class="text-center">{{ getAccuracy(gr) }}</td>
            <td class="text-center">{{ getSequenceAccuracy(gr) }}</td>
            <td class="text-center">{{ getRoundTime(gr) }}</td>
            <td class="text-center">{{ getScore(gr) }}</td>
            <td class="text-center">{{ getGroupTime(gr) }}</td>
            <td class="text-center" style="width:80px">
              <v-tooltip top>
                <template #[`activator`]="{ on, attrs }">
                  <v-btn @click="remove(gr.map(i => i.result_id))"  icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </template>
                Удалить
              </v-tooltip>
            </td>
          </tr>
          <tr :key="ind+'_items'" v-if="opened == ind">
            <td class="w-fit px-1"></td>
            <td colspan="10" class="px-0">
              <data-table @remove="remove" :items="gr"></data-table>
            </td>
          </tr>
        </template>
        
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
import DataTable from './DataTable'
export default {
  components: {
    DataTable,
  },
  props: {
    groups: {
      default: () => [],
      type: Array
    },
    assort: {
      default: 'day',
      type: String,
    },
  },
  data: () => ({
    opened: null
  }),
  computed: {
    users() {
      return this.$store.state.users;
    },
  },
  methods: {
    toggleOpened(key) {      
      if (this.opened == key) {
        this.opened = null;
      } else {
        this.opened = key;
      }
      /* if (this.opened.includes(key)) {
        this.opened.splice(this.opened.indexOf(key), 1);
      } else {
        this.opened.push(key);
      } */
    },
    getUserName(id) {
      let user = this.users.find(u => u.id == id)
      let name = user.name;
      if (user.group.deep_name) {
        name += ` (${user.group.deep_name})`
      }
      return name;
    },
    getTime(group) {
      return group[0].date
    },
    getCurrentLavel(gr) {
      let e = gr[gr.length - 1];
      return e.current_level_number;
    },
    getAccuracy(gr) {
      let sum = gr.reduce((a, e) => a + +(e.accuracy || 0), 0);
      return (sum * 100 / gr.length).toFixed(0);
    },
    getSequenceAccuracy(gr) {
      let sum = gr.reduce((a, e) => a + +(e.sequence_accuracy || 0), 0);
      return (sum * 100 / gr.length).toFixed(0);
    },
    getRoundTime(gr) {
      let sum = gr.reduce((a, e) => a + +(e.reaction_speed || 0), 0);
      return (sum * 100 / gr.length).toFixed(0);
    },
    getScore(gr) {
      let sum = gr.reduce((a, e) => a + +(e.score || 0), 0).toFixed(0);
      return sum;
    },
    getGroupTime(gr) {
      
      let startItem = gr[0] || null;
      let endItem = gr[gr.length-1];
      let diff = '-';
      if (startItem && endItem) {
        diff = this.$moment.utc(this.$moment(endItem.date).diff(this.$moment(startItem.date))).format("HH:mm:ss")
      }
      return diff;
    },
    remove(ids) {
      this.$emit('remove',ids)
    }
  }
}
</script>

<style>

</style>