<template>
  <v-simple-table dense class="table-bordered">
      <template #default >
         <thead class="grey lighten-3">
            <tr class="text-caption grey--text ">
              <th class="text-left font-weight-regular">
                #
              </th>
              <th class="text-left font-weight-regular">
                Дата
              </th>
              <th class="text-left font-weight-regular">
                Пользователь
              </th>
              <th class="text-left font-weight-regular">
                Тренинг
              </th>
              <th class="text-center font-weight-regular">
                Уровень
              </th>
              <th class="text-center font-weight-regular">
                Память, %
              </th>
              <th class="text-center font-weight-regular">
                Внимание, %
              </th>
              <th class="text-center font-weight-regular">
                Скорость реакции, %
              </th>
              <th class="text-center font-weight-regular">
                Баллы
              </th> 
              <th class="text-center font-weight-regular">
              </th>              
            </tr>
          </thead>
          <tbody>
            <tr v-for="(round,ind) in items" :key="ind">
              <td>{{ ind + 1 }}</td>
              <td>{{ new Date(round.date) | moment("DD.MM.YYYY HH:mm:ss") }}</td>
              <td>{{ getUserName(round.user_id) }}</td>
              <td>{{ getTraningName(round.training_id) }}</td>
              <td class="text-center">{{ round.current_level_number }}</td>
              <td class="text-center">{{ (round.accuracy * 100).toFixed(0) }}</td>
              <td class="text-center">{{ (round.sequence_accuracy * 100).toFixed(0) }}</td>
              <td class="text-center">{{ (round.reaction_speed * 100).toFixed(0) }}</td>
              <td class="text-center">{{ (round.score).toFixed(0) }}</td>              
              <td class="text-center" style="width:80px">
                <v-tooltip top>
                  <template #[`activator`]="{ on, attrs }">
                    <v-btn @click="remove(round.result_id)"  icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </template>
                  Удалить
                </v-tooltip>
              </td>           
            </tr>
          </tbody>
      </template>
    </v-simple-table>
</template>

<script>
export default {
  props: {
    items: {
      default: () => [],
      type: Array
    },
  },
  computed: {
    users() {
      return this.$store.state.users;
    },
    tranings() {
      return this.$store.state.trainings;
    },
  },
  methods: {
    getUserName(id) {
      let user = this.users.find(u => u.id == id)
      let name = user.name;
      if (user.group.deep_name) {
        name += ` (${user.group.deep_name})`
      }
      return name;
    },
    getTraningName(id) {
      let traning = this.tranings.find(u => u.id == id)
      if (!traning) return '';
      return traning.human_name;
    },
    remove(id) {
      this.$emit('remove',[id])
    }
  }
}
</script>

<style>

</style>