<template>
  <div>
    
    <v-card color="blue-grey lighten-5" class="rounded-0 pa-5 mb-2">
      <v-row class="align-center">
        <v-col v-if="!userIn" cols="12" sm="2">
          <select-user-group v-model="userGroup"></select-user-group>
        </v-col>
        <v-col v-if="!userIn" cols="12" sm="3">
          <v-select
            v-model="userId"
            :items="filtredUsers"
            item-value="id"
            item-text="name"
            dense
            hide-details
            clearable
            label="Пользователь"
            color="teal"
            class="flex-grow-0"
            item-color="teal lighten-1">
          </v-select>
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
            v-model="trainingGroup"
            :items="trainingGroups"
            item-value="id"
            item-text="name"
            dense
            hide-details
            clearable
            label="Группа тренингов"
            color="teal"
            item-color="teal lighten-1">
          </v-select>
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
            v-model="training"
            :items="filtredTranings"
            item-value="id"
            item-text="name"
            dense
            hide-details
            clearable
            label="Тренинг"
            color="teal"
            item-color="teal lighten-1">
          </v-select>
        </v-col>
        <v-col cols="12" sm="3">
          <v-dialog
            v-model="modal"
            persistent
            width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="datesText"
                label="Временной интервал"
                readonly
                dense
                @click:clear="clearDates()"                
                hide-details
                clearable
                color="teal"
                v-bind="attrs"
                v-on="on"
                class="flex-grow-0"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              scrollable
              first-day-of-week="1"
              range
              color="teal">
              <v-spacer></v-spacer>
              <v-btn text color="blue-grey" @click="modal = false" >
                Отмена
              </v-btn>
              <v-btn text color="teal" @click="modal = false">
                Сохранить
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col v-if="userIn" cols="12" md="2" sm="3">
          <v-select
            v-model="assort"
            :items="assorts"
            item-value="key"
            item-text="name"
            dense
            hide-details
            label="Группировать"
            color="teal"
            item-color="teal lighten-1">
          </v-select>
        </v-col>
      </v-row>  
      <v-row class="align-center mt-5">
        <v-col v-if="!userIn" cols="12" md="3" sm="5">
          <v-select
            v-if="tags.length"
            v-model="tagId"
            :items="tags"
            item-value="id"
            item-text="name"
            dense
            hide-details
            clearable
            label="Тег"
            color="teal"
            item-color="teal lighten-1">
          </v-select>
        </v-col>
        <v-spacer></v-spacer>
        <v-col v-if="!userIn" cols="12" md="2" sm="3">
          <v-select
            v-model="assort"
            :items="assorts"
            item-value="key"
            item-text="name"
            dense
            hide-details
            label="Группировать"
            color="teal"
            item-color="teal lighten-1">
          </v-select>
        </v-col>
        <v-col cols="12" md="3" sm="4" v-if="!userId">
          <v-checkbox
            v-model="splitUsers"
            label="Группировать по пользователям"
             color="teal"
          ></v-checkbox>
        </v-col>
      </v-row>    
    </v-card>
    <div class="d-flex">
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template #[`activator`]="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="getResults"><v-icon>mdi-reload</v-icon></v-btn>
        </template>
        <span>Обновить</span>
      </v-tooltip>
    </div>
    <v-skeleton-loader v-if="loading" type="article"></v-skeleton-loader>
    <template v-else>
      <v-expansion-panels v-if="!userId && splitUsers" tile accordion>
        <v-expansion-panel
          v-for="id in activeUsersId"
          :key="id"
          active-class="pb-5"
        >
          <v-expansion-panel-header class="px-2 py-0">
            <template v-slot:actions class="order-1">
              <v-icon color="teal" >
                mdi-menu-down
              </v-icon>
            </template>
            <v-row align="center" class="my-0 order-2" dense>
              <v-col cols="12" sm="3">
                <div class="ml-2">{{ getUserName(id) }}</div>
              </v-col>
              <v-spacer/>
              <v-col cols="12" sm="4">
                <div class="d-flex">
                  <div class="ml-2">
                    {{ new Date(getUserStartDay(id)) | moment("DD.MM") }} ~ 
                    {{ new Date(getUserEndDay(id)) | moment("DD.MM") }}
                  </div>
                </div>                
              </v-col>    
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="sr-ex-panel-content">
            <data-table @remove="remove" v-if="assort == 'no'" :items="getUserResults(id)"></data-table>
            <data-group-table @remove="remove" v-else :assort="assort" :groups="getUserResults(id)"></data-group-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <template v-else>
        <data-table @remove="remove" v-if="assort == 'no'" :items="results"></data-table>
        <data-group-table @remove="remove" v-else :assort="assort" :groups="groupedResults"></data-group-table>
      </template>
    </template>
    

  </div>
</template>

<script>

import SelectUserGroup from '../main/SelectUserGroup'
import DataTable from './DataTable'
import DataGroupTable from './DataGroupTable'
export default {
  props: {
    userIn: null,
  },
  components: {
    SelectUserGroup,
    DataTable,
    DataGroupTable,
  },
  data: () => ({
    assort: 'day',
    assorts: [
      {
        key: 'no',
        name: 'Нет'
      }, 
      {
        key: 'day',
        name: 'По дням'
      }, 
      {
        key: 'session',
        name: 'По сессиям'
      }, 
    ],
    splitUsers: true,
    userGroup: null,
    userId: null,
    tagId: null,
    trainingGroups: [],
    trainingGroup: null,
    training: null,
    modal: false,
    dates: [],
    results: [],
    loading: true,
  }),
  created() {
    if (this.userIn) {
      this.userId = this.userIn.id;
    }
    this.setDefaultDates();
    this.getTrainingGroups();
    this.getResults();
  },
  watch: {
    userGroup() {
      this.getResults();
    },
    userId() {
      this.getResults();
    },
    tagId() {
      this.getResults();
    },
    trainingGroup() {
      this.getResults();
    },
    training() {
      this.getResults();
    },
    dates() {
      this.getResults();
    },
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    trainings() {
      return this.$store.state.trainings;
    },
    users() {
      return this.$store.state.users;
    },
    tags() {
      return this.$store.state.tags;
    },
    filtredUsers() {
      if (this.userGroup) {
        let groupIds = this.groupIds(this.userGroup);
        return this.users.filter(u => groupIds.includes(u.group.id));
      }      
      return this.users;
    },
    filtredTranings() {
      if (this.trainingGroup) {
        return this.trainings.filter(t => t.group.id == this.trainingGroup);
      }
      return this.trainings; 
    },
    datesText() {
      return this.dates.join(' ~ ');
    },
    activeUsersId() {
      return [...new Set(this.results.map(item => item.user_id))];
    },
    groupedResults() {
      let key = this.assort == 'day' ? 'day_group' : 'training_session';
      if (!this.results.length) return [];
      return this.groupByField(this.results,key);
    }
  },
  methods: {
    groupByField(items,key) {
      let grouped = {};
      items.forEach(e => {
        let k = e[key] ? e[key] : 'null';
        if (!grouped[k]) grouped[k] = [];
        grouped[k].push(e)
      });
      return Object.values(grouped);
    },
    getUserName(id) {
      let user = this.users.find(u => u.id == id)
      if (!user) {
        return 'Пользователь не определен';
      }
      let name = user.name;
      if (user.group.deep_name) {
        name += ` (${user.group.deep_name})`
      }
      return name;
    },
    getUserResults(id) {
      let results = this.results.filter(r => r.user_id == id)
      if (this.assort != 'no') {
        let key = this.assort == 'day' ? 'day_group' : 'training_session';
        results = this.groupByField(results,key)
      }
      return results;
    },
    getUserStartDay(id) {
      let results = this.results.filter(r => r.user_id == id);
      let min = results.reduce((p, v) => {
              return ( p < v.date ? p : v.date );
            }, results[0].date);
      return min;
    },
    getUserEndDay(id) {
      let results = this.results.filter(r => r.user_id == id);
      let max = results.reduce((p, v) => {
              return ( p > v.date ? p : v.date );
            }, results[0].date);
      return max;
    },
    setDefaultDates() {
      if (!this.userIn) {
        let date = this.$moment()
        let endDate = date.toISOString().slice(0,10);
        date.subtract(1, 'months')
        let startDate = date.toISOString().slice(0,10);
        this.dates = [startDate,endDate]
      }
      
    },
    getTrainingGroups() {
      let url = '/trainings-groups';
       this.$api.get(url)
        .then(resp => {
          this.trainingGroups = resp.data._embedded.groups;
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    },
    groupIds(id) {
      let ids = [];
      if (id) {        
        ids = this.$store.state.userGroups
          .filter(g => g.id == id || g.parent_id == id)
          .map(g => g.id);
      }
      return ids;
    },
    getResults() {
      this.loading = true; 
      let url = '/results/raw';
      let params = { 
        user_groups: this.groupIds(this.userGroup),
        user_id: this.userId,
        training_group_id: this.trainingGroup,
        training_id: this.training,
        dates: this.dates,
        assort: this.assort,
        tag_id: this.tagId,
      };
      this.$api.get(url,{params})
        .then(resp => {
          this.results = resp.data._embedded.results;
          this.loading = false; 
        })
        .catch(error => {   
          console.log(error);        
          this.$store.dispatch('addRequestError', error)
        })
    }, 
    clearDates() {
      this.dates = [];
    }, 
    remove(ids) {
      let url = `/results/delete`;
      let params = {ids};
      this.$api.post(url,params)
        .then(() => {       
          this.getResults() 
          this.$store.dispatch('addMessage', 'Результаты удалены')
        })
        .catch(error => {           
            this.$store.dispatch('addRequestError', error)
        })
    }
  }
}
</script>

<style>

</style>